<template>
  <v-card
    class="d-flex flex-wrap justify-space-between mb-5"
    max-width="100%"
    outlined
  >
    <v-list width="270" max-width="100%">
      <v-list-item two-line>
        <v-icon size="50" class="mr-5">mdi-notebook-edit</v-icon>
        <v-list-item-content>
          <v-list-item-title class="text-capitalize headline mb-1"
            >Karmanotin
            {{
              promoOverviewHeaderInfo && "" && promoOverviewHeaderInfo.brand
            }}</v-list-item-title
          >
          <v-list-item-subtitle
            >Model
            {{
              promoOverviewHeaderInfo && promoOverviewHeaderInfo.version
            }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="text-wrap"
            >Analytic Period:
            {{
              promoOverviewHeaderInfo && promoOverviewHeaderInfo.period
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-list
      class="d-flex justify-space-between align-content-center flex-wrap"
      width="500"
      max-width="100%"
    >
      <!-- <v-list width="250">
        <v-list-item>
          <v-select
            dense
            v-model="selectedRxType"
            :items="outputY"
            label="Sales Unit"
            @change="onSelectedRxTypeChange"
          ></v-select>
        </v-list-item>
      </v-list> -->
      <v-list width="250">
        <v-list-item>
          <v-select
            dense
            v-model="selectedGroupLevel"
            :items="group_levels"
            label="Group Level"
            @change="onSelectedGroupLevelChange"
          ></v-select>
        </v-list-item>
      </v-list>
      <v-list width="250">
        <v-list-item>
          <v-select
            dense
            v-model="selectedTacticGroup"
            :items="tactic_groups"
            label="Promo Group"
            @change="onSelectedTacticGroupChange"
          >
            <template slot="selection" slot-scope="{ item }">
              {{ item }}
            </template>
            <template slot="item" slot-scope="{ item }">
              {{ item }}
            </template>
          </v-select>
        </v-list-item>
      </v-list>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "PromoOverview",
  data: () => ({
    selectedRxType: null,
    selectedGroupLevel: null,
    selectedTacticGroup: null,
    // outputY:"sales"
    outputY: ["sales"],
  }),
  methods: {
    async init() {
      // set default option for RxType
      // this.selectedRxType = this.rx_types[0];
      this.selectedRxType = "sales";

      // click the default option
      this.onSelectedRxTypeChange();
    },
    async onSelectedRxTypeChange() {
      // set selected RxType in the store
      // this.$store.commit("SET_PROMO_OVERVIEW_RX_TYPE", this.selectedRxType);
      this.$store.commit("SET_PROMO_OVERVIEW_RX_TYPE", this.rx_types[0]);

      // get the list of GroupLevel
      this.$store.commit("SET_GROUP_LEVELS");

      // set default option for GroupLevel
      this.selectedGroupLevel = this.group_levels[0];

      // click the default option
      this.onSelectedGroupLevelChange();
    },
    onSelectedGroupLevelChange() {
      // set selected GroupLevel in the store
      this.$store.commit(
        "SET_PROMO_OVERVIEW_GROUP_LEVELS",
        this.selectedGroupLevel
      );

      // get the list of TacticGroup
      this.$store.commit("SET_TACTIC_GROUPS");

      // set default option for TacticGroup
      this.selectedTacticGroup = this.tactic_groups[0];

      // click the default option
      this.onSelectedTacticGroupChange();
    },
    onSelectedTacticGroupChange() {
      // set selected TacticGroup in the store
      this.$store.commit(
        "SET_PROMO_OVERVIEW_TACTIC_GROUPS",
        this.selectedTacticGroup
      );

      // get metrics
      this.$store.commit("SET_PROMO_OVERVIEW_METRICS");

      // get charts
      this.$store.commit("SET_PROMO_OVERVIEW_CHARTS");
    },
  },
  computed: {
    language() {
      return this.$store.state.settings.language;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    promoOverviewHeaderInfo() {
      return this.$store.getters.promoOverviewHeaderInfo;
    },
    rx_types() {
      return this.$store.state.results.rx_types;
    },
    group_levels() {
      return this.$store.state.results.group_levels;
    },
    tactic_groups() {
      return this.$store.state.results.tactic_groups;
    },
  },
  watch: {
    language() {
      // re-render the charts with the change of language
      this.$store.commit("SET_PROMO_OVERVIEW_CHARTS");
    },
    theme() {
      // re-render the charts with the change of theme
      this.$store.commit("SET_PROMO_OVERVIEW_CHARTS");
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped></style>
